export default {
    name: 'web',
    component: () => import(/* webpackChunkName: 'web' */ '@/modules/fray/layouts/WebLayout.vue'),
    children: [
        {
            path: '',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ '@/modules/fray/views/HomeView.vue' )
        },
        {
            path: 'anuncios',
            name: 'anuncios',
            component: () => import(/* webpackChunkName: "anuncios" */ '@/modules/fray/views/AnunciosView.vue' )
        },
        {
            path: 'anuncios/:id',
            name: 'anuncio',
            component: () => import(/* webpackChunkName: "anuncio" */ '@/modules/fray/views/AnuncioView.vue' )
        },
        {
            path: 'noticias',
            name: 'noticias',
            component: () => import(/* webpackChunkName: "noticias" */ '@/modules/fray/views/NoticiasView.vue' )
        },
        
        {
            path: 'noticias/:id',
            name: 'noticia',
            component: () => import(/* webpackChunkName: "noticia" */ '@/modules/fray/views/NoticiaView.vue' )
        },

        {
            path: 'oferta',
            name: 'oferta',
            component: () => import(/* webpackChunkName: "ofeducatiiva" */ '@/modules/fray/views/OfertaEducativaView.vue' )
        },

        {
            path: 'oferta/:id',
            name: 'detalleoferta',
            component: () => import(/* webpackChunkName: "detalleoferta" */ '@/modules/fray/views/DetalleOfertaView.vue' )
        },

        {
            path: 'areas',
            name: 'areas',
            component: () => import(/* webpackChunkName: "areas" */ '@/modules/fray/views/AreasView.vue' )
        },

        {
            path: 'area/:id',
            name: 'area',
            component: () => import(/* webpackChunkName: "area" */ '@/modules/fray/views/AreaView.vue' )
        },
        {
            path: 'departamento/:id',
            name: 'departamento',
            component: () => import(/* webpackChunkName: "departamento" */ '@/modules/fray/views/DepartamentoView.vue' )
        },
        {
            path: 'administracion',
            name: 'administracion',
            component: () => import(/* webpackChunkName: "administracion" */ '@/modules/fray/views/AdministracionView.vue' )
        },
        {
            path: 'administracion/tutores',
            name: 'tutores',
            component: () => import(/* webpackChunkName: "tutores" */ '@/modules/fray/views/TutoresView.vue' )
        },
        {
            path: 'administracion/documentos',
            name: 'documentacion',
            component: () => import(/* webpackChunkName: "documentacion" */ '@/modules/fray/views/DocumentacionView.vue' )
        },
        {
            path: 'bilinguismo',
            name: 'bilinguismo',
            component: () => import(/* webpackChunkName: "bilinguismo" */ '@/modules/fray/views/BilinguismoView.vue' )
        },
        {
            path: 'bilinguismo/escuela',
            name: 'escuela',
            component: () => import(/* webpackChunkName: "escuela" */ '@/modules/fray/views/LaescuelaView.vue' )
        },
        {
            path: 'bilinguismo/info',
            name: 'infobil',
            component: () => import(/* webpackChunkName: "infobil" */ '@/modules/fray/views/InformacionbilingueView.vue' )
        },
        {
            path: 'bilinguismo/clases',
            name: 'clasesbil',
            component: () => import(/* webpackChunkName: "clasesbil" */ '@/modules/fray/views/ClasesbilingueView.vue' )
        },
        {
            path: 'bilinguismo/auxiliares',
            name: 'auxiliaresbil',
            component: () => import(/* webpackChunkName: "auxiliaresbil" */ '@/modules/fray/views/AuxiliaresconversacionView.vue' )
        },
        {
            path: 'bilinguismo/auxiliares/recursos/:id',
            name: 'recursosaux',
            component: () => import(/* webpackChunkName: "recursosaux" */ '@/modules/fray/views/RecursosauxiliarView.vue' )
        },
        {
            path: 'bilinguismo/auxiliares/actividades/:id',
            name: 'actividadesaux',
            component: () => import(/* webpackChunkName: "actividadesaux" */ '@/modules/fray/views/ActividadesauxiliarView.vue' )
        },
        {
            path: 'bilinguismo/actividades',
            name: 'actividadbil',
            component: () => import(/* webpackChunkName: "actividadbil" */ '@/modules/fray/views/ActividadbilingueView.vue' )
        },
        {
            path: 'bilinguismo/recursos',
            name: 'recursobil',
            component: () => import(/* webpackChunkName: "actividadbil" */ '@/modules/fray/views/RecursosbilingueView.vue' )
        },
        {
            path: 'bilinguismo/actividades/:id',
            name: 'detalleactividad',
            component: () => import(/* webpackChunkName: "detalleactividad" */ '@/modules/fray/views/DetalleactividadView.vue' )
        },
    ]
}