import { createStore } from 'vuex'

import VuexPersistence from 'vuex-persist'

import fray from '../modules/fray/store'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const store = createStore({
  modules: {
    fray,
  },
  plugins: [vuexLocal.plugin]
})

export default store



