import { createRouter, createWebHistory } from 'vue-router'

import webRouter from '../modules/fray/router'

const routes = [
  {
    path: '/',
    name: 'base',
    ...webRouter
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(){
    window.scrollTo(0,0)
  }
})

export default router
