import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import './styles/main.sass'
import './styles/navBar.sass'
import './styles/footer.sass'

createApp(App).use(store).use(router).mount('#app')
