
import publicApi from "@/api/publicApi";

import defaultImg from '@/assets/img/ESCUDO_FRAY.png'

export const cargaNoticias = async ({commit}) => {
    const { data } = await publicApi.get("/noticias?sort=id:desc")
    if (!data){
        commit('cargaNoticias', [])
        return
    }
    commit('cargaNoticias', data.data)
}

export const cargaUltimasCinco = async ({commit}) => {

    const { data } = await publicApi.get('/noticias?sort=id:desc&populate[portada][fields][0]=url&pagination[start]=0&pagination[limit]=5')
    if (!data){
        commit('cargaUltimasCinco', [])
        return
    }   

    const noticias = data.data.map((noticia, index) => {
        // TODO: Imagen por defecto en el servidor
        return {
            id: noticia.id,
            idx: index,
            titulo: noticia.attributes.titulo,
            cuerpo: noticia.attributes.cuerpo,
            portada: (noticia.attributes.portada.data) ? noticia.attributes.portada.data.attributes.url : defaultImg
        }
    })

    commit('cargaUltimasCinco', noticias)

}

export const cargaUltimosCinco = async ({commit}) => {

    const { data } = await publicApi.get('/tablon-de-anuncios?sort=id:desc&populate[portada][fields][0]=url&pagination[start]=0&pagination[limit]=5')
    if (!data){
        commit('cargaUltimosCinco', [])
        return
    }   

    const anuncios = data.data.map((anuncio, index) => {
        // TODO: Imagen por defecto en el servidor
        return {
            id: anuncio.id,
            idx: index,
            titulo: anuncio.attributes.titulo,
            portada: (anuncio.attributes.portada.data) ? anuncio.attributes.portada.data.attributes.url : defaultImg
        }
    })
    
    commit('cargaUltimosCinco', anuncios)

}


export const cargaOfertaEducativaHome = async ({commit}) => {
    const { data } = await publicApi.get('/oferta-educativas?populate[portada][fields][0]=url')
    if (!data){
        commit('cargaOfertaHome', [])
        return
    }

    const ofertaHome = data.data.map(oferta => {
        return {
            id: oferta.id,
            nombre: oferta.attributes.nombre,
            descripcion: oferta.attributes.descripcion,
            portada: (oferta.attributes.portada.data) ? oferta.attributes.portada.data.attributes.url : defaultImg
        }
    })

    commit('cargaOfertaHome', ofertaHome)
}


