

export const cargaNoticias = (state, noticias) => {
    state.noticias = noticias
    state.isLoading = false
}

export const cargaUltimasCinco = (state, noticias) => {
    state.ultimasCinco = noticias
    state.isLoading = false
}

export const cargaUltimosCinco = (state, anuncios) => {
    state.ultimosCinco = anuncios
    state.isLoading = false
}

export const cargaOfertaHome = (state, ofertaHome) => {
    state.ofertaHome = ofertaHome
}
